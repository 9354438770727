<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Yes'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-haspets.png'"
        :selected="personalDetails.pets === 'Yes'"
        @selected="save('Yes')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'No'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-nopets.png'"
        :selected="personalDetails.pets === 'No'"
        @selected="save('No')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'ProfileChildren',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  mounted() {
    this.$emit('progress', '50%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number: () => '5',
    start() {
      return this.$router.history.current.name === 'WriteMyWillPets'
    },
    forwardTo() {
      if (
        this.basket &&
        this.basket.coupon &&
        this.basket.coupon.charity &&
        this.start
      )
        return '/write_my_will/charity_option'
      else if (this.basket && this.basket.coupon && this.basket.coupon.charity)
        return '/profile/charity_option'
      else if (this.start) return '/write_my_will/address'
      return '/profile/address'
    },
    backTo() {
      if (this.start) return '/write_my_will/children'
      return '/profile/children'
    },
    heading() {
      return 'Do you have pets?'
    },
    subHeading() {
      return 'We ask about pets as you are able to name people to look after them should the need arise.'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    isValidated() {
      if (this.personalDetails.pets !== null) return true
      return false
    },
    children() {
      return this.$store.getters.people.filter(
        (person) => person.child || person.child_step
      )
    }
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          pets: value
        })
      }
    }
  }
}
</script>

<style></style>
